import { ServiceLayer } from "../services/serviceLayer";
import { emptyModal } from "./DAO";

export interface QuestionOption {
    code: string;
    label: string;
    sequence: number;
    value: string;
    helpText: string;
    isEligible: boolean;
}

export interface QuestionAnswer {
    key: string;
    name: string;
    answer: string | boolean | any;
    answers?: Array<any>; //TODO: Change any to proper interface
    rowIndex: number;
    required: boolean;
    enabled: boolean;
    hidden: boolean;
    group: string;
    enabledOperator?: string;
    maximum?: string;
    minimum: string;
    questionDefault?: Array<any>; //TODO: Change any to proper interface
    questionHelpText?: string;
    questionLabel?: string;
    questionOptions: Array<QuestionOption>;
    sequence?: number;
    severity?: string;
    status?: string;
    type: string;
    isVehicleUpdatedbyVEH1: boolean;
    radioButtonLabel1?: string;
    radioButtonLabel2?: string;
    parentKey?: string; //used in uw questionnaire only
    parentSequence?: string; //used in uw questionnaire only
}

export const QuestionAnswerHelper = {
    useDefaultValue<questionAnswer>(){
        if (!!!this.answer && !!this.questionDefault && this.questionDefault.length > 0) {
            this.answer = this.questionDefault[0].code;
        }
  }
}

export class Questionnaire {
    public key: string;
    public questionAnswer: Array<QuestionAnswer>;

    public constructor() {
    }
    public addOrUpdateQuestion(questionKey:string, questionValue : string) : void{
        let questionInGroup = this.findQuestion(questionKey);
        if(questionInGroup != null) {
            questionInGroup.answer = questionValue;
        }else{
            let newQuestionAnswer= {
                key : questionKey,
                name : questionKey,
                answer : questionValue ?? "",
                rowIndex: 1
            } as QuestionAnswer ;
            this.questionAnswer.push(newQuestionAnswer);
        }
    }

    public findQuestion(questionKey: string): QuestionAnswer {
        let questionInGroup = this.questionAnswer?.filter(x => x.key == questionKey);
        if (questionInGroup != null)
            return questionInGroup[0];
        return null;
    }

    public findQuestions(questionKey: string): Array<QuestionAnswer> {
        let questionInGroup = this.questionAnswer?.filter(x => x.key == questionKey);
        if (questionInGroup != null)
            return questionInGroup;
        return null;
    }

    public findQuestionByKeyAndRowIndex(key: string, rowIndex: number): QuestionAnswer {
        let questionInGroup = this.questionAnswer?.find(x => x.key == key && x.rowIndex == rowIndex);
        if (questionInGroup != null)
            return questionInGroup;
        return null;
    }

    public upsertQuestionAnswer(questionKey: string, answer: string, rowIndex: number): void {
        let questionInGroup = this.questionAnswer?.find(x => x.rowIndex == rowIndex && x.key == questionKey);
        if (questionInGroup != null) {
            questionInGroup.answer = answer;
        } else {
            let newQuestionAnswer: QuestionAnswer = new emptyModal();
            newQuestionAnswer.key = questionKey;
            newQuestionAnswer.name = questionKey;
            newQuestionAnswer.answer = answer ?? "";
            newQuestionAnswer.group = this.key;
            newQuestionAnswer.rowIndex = rowIndex;
            this.questionAnswer.push(newQuestionAnswer);
        }
    }
    
    public static fromJSON(jsonObj: Questionnaire): Questionnaire {
        let instance = new Questionnaire();
        instance.key = jsonObj.key;
        instance.questionAnswer = jsonObj.questionAnswer;
        return instance;
    }
}

export interface MessageExtendedStatus {
    extendedStatusCode: string;
    extendedStatusDesc: string;
    extendedStatusSeverity: string;
}

export interface DotMessageStatus {
    extendedStatus: Array<MessageExtendedStatus>;
    msgStatusCode: string;
    msgStatusDesc: string;
}

export enum QuestionnaireKeys {
    dot = "dot",
    AccountInfo = "AccountInfo",
    uw = "uw",
    loss = "loss",
    vehicle = "vehicle",
    vehiclePrefill = "vehiclePrefill",
    driver = "driver",
    product = "product",
    application = "application",
    EvaluateSubmission = "EvaluateSubmission",
}

export class ResponseContainer {
    constructor() {
    }

    public static fromJSON(jsonObj: ResponseContainer) {
        let instance = new ResponseContainer();
        instance.msgStatus = jsonObj.msgStatus;
        instance.number = jsonObj.number;
        instance.questionnaire = [];
        //Add findQuestion function to Questionnare because it is a data type without behaviors
        if (!!jsonObj.questionnaire && jsonObj.questionnaire.length > 0) {
            jsonObj.questionnaire.forEach(element => {
                let qsnnr = new Questionnaire();
                qsnnr.key = element.key;
                qsnnr.questionAnswer = element.questionAnswer;
                instance.questionnaire.push(qsnnr);
            })
        }
        instance.finalSubmitPage = jsonObj.finalSubmitPage;
        instance.currentPage = jsonObj.currentPage;
        instance.cabHttpStatusCode = jsonObj.cabHttpStatusCode;
        instance.cabResponse = jsonObj.cabHttpStatusCode;
        instance.isStatusReceived = jsonObj.isStatusReceived;
        return instance;
    }

    public msgStatus: any;
    public number: string;
    public questionnaire: Array<Questionnaire>;
    public finalSubmitPage: string;
    public currentPage: string;
    public cabHttpStatusCode: string;
    public cabResponse: any;
    public isStatusReceived: string;

    private findQuestionnaire(questionnaireKey: string): Questionnaire {
        let aQuestionnaire = this.questionnaire?.filter(x => x.key == questionnaireKey);
        if (aQuestionnaire != null)
            return aQuestionnaire[0];
        return null;
    }

    private findQuestionAnswers(questionnaireKey: string): Array<QuestionAnswer> {
        let aQuestionnaire = this.findQuestionnaire(questionnaireKey);
        if (aQuestionnaire != null)
            return aQuestionnaire[0].questionAnswer;
        return null;
    }

    public get dotQsnr(): Questionnaire {
        return this.findQuestionnaire(QuestionnaireKeys.dot);
    }
    public get accountInfoQsnr(): Questionnaire {
        return this.findQuestionnaire(QuestionnaireKeys.AccountInfo);
    }
    public get uwQsnr(): Questionnaire {
        return this.findQuestionnaire(QuestionnaireKeys.uw);
    }
    public set uwQsnr(value: Questionnaire) {
        this.findQuestionnaire(QuestionnaireKeys.uw).questionAnswer = value.questionAnswer;
    }
    public get losssHistoryQuestionnaire(): Questionnaire {
        return this.findQuestionnaire(QuestionnaireKeys.loss);
    }
    public get vehicleQsnr(): Questionnaire {
        return this.findQuestionnaire(QuestionnaireKeys.vehicle);
    }
    public get vehiclePrefillQsnr(): Questionnaire {
        return this.findQuestionnaire(QuestionnaireKeys.vehiclePrefill);
    }
    public get driversQsnr(): Questionnaire {
        return this.findQuestionnaire(QuestionnaireKeys.driver);
    }
    public get productQsnr(): Questionnaire {
        return this.findQuestionnaire(QuestionnaireKeys.product);
    }
    public get applicationQsnr(): Questionnaire {
        return this.findQuestionnaire(QuestionnaireKeys.application);
    }
    public get accountState(): string {
        return this.accountInfoQsnr?.findQuestion("accountState")?.answer;
    }

    public areAllProductCargoQuestionsAnswered(): Boolean {
        // If cargoIndicator in the Policy holder screen is answered as 'YES' 
        // and also cargo related UW questions are answered as expected values,
        // then we have certain cargo questions displayed in product screen.
        if (!!!this.accountInfoQsnr 
            || !!!this.uwQsnr 
            || !!!this.productQsnr 
            || !!!this.uwQsnr.findQuestion('unattendedLoadedTrailers') 
            || !!!this.productQsnr.findQuestion('cargoNameInsuredParent')) {
            return true;
        }
        if (this.accountInfoQsnr.findQuestion('cargoIndicator').answer == "Yes") {
            let unattendedLoadedTrailers = this.uwQsnr.findQuestion('unattendedLoadedTrailers').answer;
            let actualDamageInLossEvent = this.uwQsnr.findQuestion('actualDamageInLossEvent').answer;
            let IsDOTBillIssued = this.uwQsnr.findQuestion('IsDOTBillIssued').answer;
            if (this.productQsnr.findQuestion('cargoIncluded').answer != "No"
                && unattendedLoadedTrailers != "Y"
                && actualDamageInLossEvent != "Y"
                && IsDOTBillIssued != "N") {
                if (this.productQsnr.findQuestion('cargoNameInsuredParent').answer == "") {
                    return false;
                }
            }
        }
        return true;
    }

    public areAllProductTrailerInterchangeQuestionsAnswered(serviceLayer: ServiceLayer): Boolean {
        // If newTruckingModel, then TrailerInterchangeIndicator is displayed in Policyholder else Underwriting screen
        // If TrailerInterchangeIndicator is answered as 'YES', 
        // then we have certain trailer interchange questions displayed in product screen. 
        if (!!!this.accountInfoQsnr 
            || !!!this.uwQsnr 
            || !!!this.productQsnr
            || !!!this.productQsnr.findQuestion('trailerDamageLimit')) {
            return true;
        }
        const policyEffectiveDate = this.accountInfoQsnr.findQuestion('policyEffectiveDate').answer;
        var isNTM = serviceLayer.getPolicyHolderVersionNTM(policyEffectiveDate, this.accountState);
        var accountTIIndicator = this.accountInfoQsnr.findQuestion('trailerInterchangeIndicator');
        var uwTIIndicator = this.uwQsnr.findQuestion('trailerInterchangeParent');
        if ((isNTM && (!!accountTIIndicator && accountTIIndicator.answer != "No"))
            || (!isNTM && (!!uwTIIndicator && uwTIIndicator.answer != "N"))) {
            if (this.productQsnr.findQuestion('trailerDamageLimit').answer == ""
                || this.productQsnr.findQuestion('trailerZoneTo').answer == "") {
                return false;
            }
        }
        return true;
    }

    public areAllUWCargoQuestionsAnswered(): Boolean {
        // If cargoIndicator in the Policy holder screen is answered as 'YES', 
        // then we have certain cargo questions displayed in underwriting screen.
        if (!!!this.accountInfoQsnr 
            || !!!this.uwQsnr
            || !!!this.uwQsnr.findQuestion('unattendedLoadedTrailers')) {
            return true;
        }
        if (this.accountInfoQsnr.findQuestion('cargoIndicator').answer == "Yes") {
            if (this.uwQsnr.findQuestion('unattendedLoadedTrailers').answer == ""
                || this.uwQsnr.findQuestion('isLoadedTrailersSecured').answer == ""
                || this.uwQsnr.findQuestion('trailerTrackingDevices').answer == ""
                || this.uwQsnr.findQuestion('trailerEquipedAlarms').answer == ""
                || this.uwQsnr.findQuestion('refrigerationTrailersParent').answer == ""
                || this.uwQsnr.findQuestion('actualDamageInLossEvent').answer == ""
                || this.uwQsnr.findQuestion('IsDOTBillIssued').answer == ""
                || this.uwQsnr.findQuestion('legalLiabilityPropertyParent').answer == "") {
                return false;
            }
        }
        return true;
    }
}
