import { CommonService } from 'src/app/services/commonService';
import { Component, OnInit } from '@angular/core';
import { DAO } from 'src/app/modal/DAO';
import { SiteBanner } from 'src/app/modal/SiteBanner';

@Component({
  selector: 'app-site-banner',
  templateUrl: './site-banner.component.html',
  styleUrls: ['./site-banner.component.css']
})
export class SiteBannerComponent implements OnInit {

  public siteBanner: SiteBanner;
  
  constructor(private commonService: CommonService, public dao:DAO) { }

  ngOnInit(): void {
    setTimeout(() => {
        this.getSiteBanner();    
    }, 1000);
  }

  public getSiteBanner() {
      let payload = { "urlData": [] };
      this.commonService.get('workInProgress', 'GetSiteBanner', payload)
      .subscribe(      
        (event: any) => {        
          this.siteBanner = event;           
        });
      ;        
  }
}